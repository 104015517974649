<template>
  <section class="tasks">
    <!-- Header -->
    <div class="notes-header">
      <v-row class="align-center pb-2">

        <v-col cols="6" class="pb-0">
          <h1>Tasks</h1>
        </v-col>

        <v-col cols="6" class="d-flex justify-end">
          <v-dialog v-model="isCreateDialogVisible" width="500">

            <template v-slot:activator="{ on, attrs }">
              <v-btn elevation="0" color="primary" tile v-bind="attrs" v-on="on">Add Task</v-btn>
            </template>

            <v-card class="pa-5 relative">
              <div class="ripple-loading" v-if="isLoading">
                <div></div>
                <div></div>
              </div>
              <h2 class="mb-2">Add Task</h2>
              <v-divider class="mb-6"></v-divider>
              <v-form ref="form" v-model="valid" lazy-validation>

                <v-text-field dense label="Title" outlined required v-model="task.title"></v-text-field>

                <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="task.reminder" label="Reminder Date" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                  </template>
                  <v-date-picker v-model="task.reminder" @input="dateMenu = false"></v-date-picker>
                </v-menu>

                <v-menu ref="menu" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="task.time" max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="task.time" label="Reminder Time" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
                  </template>
                  <v-time-picker v-if="timeMenu" v-model="task.time" full-width @click:minute="$refs.menu.save(task.time)"></v-time-picker>
                </v-menu>

                <v-select v-model="task.assigned_user_id" :items="staffs" label="Assigned To" required item-text="name" item-value="id" dense outlined></v-select>

                <v-btn color="primary" @click="createTask" :disabled="disableAddTaskButton" tile elevation="0">Add</v-btn>
              </v-form>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <div v-if="!isLoading && totalTasks === 0" class="mt-8">
        <h3>Tasks not found. Please add one.</h3>
      </div>
      
      <template v-else>
        <v-tabs color="primary">
          <v-tab>In Progress</v-tab>
          <v-tab>Complete</v-tab>

          <v-tab-item>
            <v-row class="mt-4">
              <v-col cols="3" v-for="task in tasks" :key="task.id" class="py-0 mb-8">
                <v-card class="pa-3 py-0" rounded elevation="0" style="height: 100%">
                  <v-row>
                    <v-col cols="12" class="teal lighten-5">
                      <div class="d-flex justify-space-between align-center">
                        <span>
                          Due on
                        </span>
                        <div>
                          <v-btn icon small color="#0f3b4d" @click="editTaskHandler(task)" v-if="task.showActions">
                            <v-icon small>edit</v-icon>
                          </v-btn>
                          <v-btn v-if="task.showActions" icon small color="error" @click="deleteTask(task)">
                            <v-icon dark>remove_circle</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <h4 class="mt-2">{{ task.reminder | humanReadableDateTime }}</h4>
                    </v-col>

                    <v-col cols="12">
                      <p class="mb-4">{{ task.task }}</p>
                      <p class="mb-1">Assigned to: <strong>{{ taskAssignedTo(task) && taskAssignedTo(task)[0].name }}</strong></p>
                      <p class="mb-0">Created by: <strong>{{ taskCreatedBy(task) && taskCreatedBy(task)[0].name }}</strong></p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          
          <v-tab-item>
            <v-row class="mt-4">
              <v-col cols="3" v-for="task in tasksCompleted" :key="task.id" class="py-0 mb-8">
                <v-card class="pa-3 py-0" rounded elevation="0" style="height: 100%">
                  <v-row>
                    <v-col cols="12" class="teal lighten-5">
                      <div class="d-flex justify-space-between align-center">
                        <span>
                          Due on
                        </span>
                        <div>
                          <v-btn icon small color="#0f3b4d" @click="editTaskHandler(task)">
                            <v-icon small>edit</v-icon>
                          </v-btn>
                          <v-btn icon small color="error" @click="deleteTask(task)">
                            <v-icon dark>remove_circle</v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <h4>{{ task.reminder | humanReadableDateTime }}</h4>
                    </v-col>

                    <v-col cols="12">
                      <p class="mb-4">{{ task.task }}</p>
                      <p class="mb-1">Assigned to: <strong>{{ taskAssignedTo(task) && taskAssignedTo(task)[0].name }}</strong></p>
                      <p class="mb-0">Created by: <strong>{{ taskCreatedBy(task) && taskCreatedBy(task)[0].name }}</strong></p>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </template>
    </div>

    <!-- Update Task Modal -->
    <v-dialog v-model="isUpdateDialogVisible" width="500">
      <v-card class="pa-5 relative">
        <div class="ripple-loading" v-if="isLoading">
          <div></div>
          <div></div>
        </div>
        <h2 class="mb-2">Update Task</h2>
        <v-divider class="mb-6"></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>

          <v-text-field dense label="Title" outlined required v-model="selectedTask.task"></v-text-field>

          <v-menu v-model="updateDateMenu" :close-on-content-click="false" :nudge-right="40" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="selectedTask.date" label="Reminder Date" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
            </template>
            <v-date-picker v-model="selectedTask.date" @input="updateDateMenu = false"></v-date-picker>
          </v-menu>

          <v-menu ref="menu" v-model="updateTimeMenu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="selectedTask.time" max-width="290px" min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="selectedTask.time" label="Reminder Time" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
            </template>
            <v-time-picker v-if="updateTimeMenu" v-model="selectedTask.time" full-width @click:minute="$refs.menu.save(selectedTask.time)"></v-time-picker>
          </v-menu>

          <v-select v-model="selectedTask.assigned_user_id" :items="staffs" label="Assigned To" required item-text="name" item-value="id" dense outlined></v-select>

          <v-select v-model="selectedTask.state" :items="stateOptions" label="State" required item-text="name" item-value="id" dense outlined></v-select>

          <v-btn color="primary" @click="updateTask(selectedTask)" tile elevation="0">Update</v-btn>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Notification -->
    <v-snackbar v-model="isSnackbarVisible" :color="snackBarType" :timeout="timeout" elevation="0" top tile>
      {{ snackbarText }}
    </v-snackbar>
  </section>
</template>

<script>
  import { createHelpers } from 'vuex-map-fields';
  import { FETCH_CURRENT_SHOP, FETCH_STAFFS } from "@/store/actions.type";
  import { mapState, mapGetters } from "vuex";

  const { mapFields } = createHelpers({
    getterType: 'tasks/getField',
    mutationType: 'tasks/updateField',
  });

  export default {
    name: 'Tasks',

    data() {
      return {
        isCreateDialogVisible: false,
        isUpdateDialogVisible: false,
        selectedTask: {},
        dateMenu: false,
        timeMenu: false,
        updateDateMenu: false,
        updateTimeMenu: false,
        valid: true,
        isLoading: false,
        task: {
          assigned_user_id: null,
          reminder: null,
          title: '',
          time: null
        },
        stateOptions: [
          { id: 'in_progress', name: 'In Progress' },
          { id: 'complete', name: 'Complete' },
        ],
        isSnackbarVisible: false,
        snackbarText: '',
        snackBarType: '',
        timeout: 1000
      }
    },

    async mounted() {
      this.isLoading = true;
      this.currentShopId = this.$route.params.id;
      if (this.currentShop === null) {
        await this.$store.dispatch(`shops/${FETCH_CURRENT_SHOP}`, this.currentShopId);
      }
      await this.fetchTasks();
      this.fetchStaffs();
      this.isLoading = false;
    },

    computed: {
      ...mapState("auth", ["admin"]),
      ...mapGetters("shops", ["currentShop"]),
      ...mapGetters("staffs", ["staffs"]),
      ...mapFields([
        'tasks',
        'tasksCompleted',
        'responseMessage'
      ]),

      disableAddTaskButton() {
        const { assigned_user_id, reminder, title, time } = this.task;
        return !assigned_user_id || !reminder || !time || title === '';
      },

      totalTasks() {
        return this.tasks.length + this.tasksCompleted.length;
      }
    },

    beforeRouteLeave(to, from, next) {
      this.tasks = [];
      next();
    },

    watch: {
      'tasks'() {
        this.showTasksAction(this.tasks);
      }
    },

    methods: {
      showTasksAction(tasks) {
        if(tasks.length === 0) { return }
        tasks.forEach(task => {
          if(+task.user_id === +this.admin.id) {
            task.showActions = true;
          }
        })
      },

      fetchStaffs() {
        this.$store.dispatch(`staffs/${FETCH_STAFFS}`, { pageNumber: 1, searchStr: '' });
      },

      taskAssignedTo(task) {
        return this.staffs?.filter(staff => {
           return +staff.id === +task.assigned_user_id
        })
      },

      taskCreatedBy(task) {
        return this.staffs?.filter(staff => {
           return +staff.id === +task.user_id;
        })
      },

      async fetchTasks() {
        this.isLoading = true;
        await this.$store.dispatch('tasks/fetchTasks', { storeId: this.currentShop.id, userId: +this.admin.id });
        this.tasks.sort(function(a,b) {
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
        this.isLoading = false;
      },

      async createTask() {
        this.isLoading = true;

        await this.$store.dispatch('tasks/createTask', {
          assigned_user_id: +this.task.assigned_user_id,
          reminder: `${this.task.reminder} ${this.task.time}:00`,
          store_id : +this.currentShop.id,
          task: this.task.title,
          user_id : +this.admin.id
        });
        this.fetchTasksAndShowSnackbar(this.responseMessage);
        this.isLoading = this.isCreateDialogVisible = false;
        this.task = {
          assigned_user_id: null,
          reminder: null,
          title: '',
          time: null
        }
      },

      async deleteTask(task) {
        this.isLoading = true;
        await this.$store.dispatch('tasks/deleteTask', {
          taskId: task.id
        });
        this.fetchTasksAndShowSnackbar(this.responseMessage);
      },

      async updateTask(task) {
        const { id, state, assigned_user_id } = task;
        this.isLoading = true;

        await this.$store.dispatch('tasks/updateTask', {
          taskId: id,
          task: {
            assigned_user_id: +assigned_user_id,
            reminder: `${this.selectedTask.date} ${this.selectedTask.time}`,
            store_id : +this.currentShop.id,
            task: task.task,
            user_id: +this.admin.id,
            state: state
          }
        });
        this.fetchTasksAndShowSnackbar(this.responseMessage);
        this.isLoading = this.isUpdateDialogVisible = false;
      },

      editTaskHandler(task) {
        this.isUpdateDialogVisible = true;
        this.selectedTask = { ...task };
        const reminder = this.selectedTask.reminder.split(' ');
        this.selectedTask.date = reminder[0];
        this.selectedTask.time = reminder[1];
      },

      async fetchTasksAndShowSnackbar(response) {
        await this.fetchTasks();
        this.snackbarText = response.message;
        this.snackBarType = response.type;
        this.isSnackbarVisible = true;
      }
    }
  }
</script>

<style lang="scss">
.tasks {
  .due-on {
    background: #0f3b4d;
    color: #fff;
    font-weight: bold;
  }

  .relative {
    position: relative;
  }

  .theme--light.v-tabs-items {
    background: transparent;
  }
}

.ripple-loading {
  background: rgba(255,255,255,0.5);
  border-radius: 15px;
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.ripple-loading div {
  animation: ripple-loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  border-radius: 50%;
  border: 4px solid #623f06;
  left: 50% !important;
  opacity: 1;
  position: absolute;
  top: 50% !important;
  transform: translateX(-50%) translateY(-50%);
}

.ripple-loading div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes ripple-loading {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 50px;
    height: 50px;
    opacity: 0;
  }
}

</style>
